// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cocktail-shop-js": () => import("./../src/pages/cocktail-shop.js" /* webpackChunkName: "component---src-pages-cocktail-shop-js" */),
  "component---src-pages-esenciales-bartending-online-gavin-wrigley-js": () => import("./../src/pages/esenciales-bartending-online-gavin-wrigley.js" /* webpackChunkName: "component---src-pages-esenciales-bartending-online-gavin-wrigley-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

